import { useRef } from "react";

export default function useIgnoreNullRef<T>(initialValue?: T) {
  const _ref = useRef<T>(initialValue);

  const ref = {
    set current(val: T) {
      if (val == null) return;
      _ref.current = val;
    },
    get current() {
      return _ref.current;
    },
    destroy() {
      _ref.current = null;
    },
  };

  return ref;
}
