import { useEffect } from "react";

import { isIOS } from "@kikoff/client-utils/src/dom";

import useIgnoreNullRef from "./useIgnoreNullRef";

export default function useFocusOnMount<Element extends HTMLElement>({
  delay = 0,
} = {}) {
  // Use this to still focus even if ref is no longer assigned to element,
  // useForm will only assign ref for a single render
  const ref = useIgnoreNullRef<Element>();

  useEffect(() => {
    // IOS keyboard does not appear on focus, causing the user to click the
    // input again which will put the cursor to the right of an input mask. If
    // the cursor is to the right, characters will not be inputted properly

    // NOTE: this does not work reliably ->
    // https://stackoverflow.com/questions/54424729/ios-show-keyboard-on-input-focus
    if (isIOS) return;
    const timeout = setTimeout(() => {
      const { current } = ref;

      (current instanceof HTMLInputElement
        ? current
        : current?.querySelector("input") || current?.querySelector("textarea")
      )?.focus();
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [ref.current]);

  return ref;
}
