import React, { useContext, useMemo, useRef } from "react";

import EventEmitter from "@kikoff/utils/src/EventEmitter";
import yup from "@kikoff/utils/src/yup";

import useError, { InteractiveError } from "./useError";
import useFocusOnMount from "./useFocusOnMount";
import useUpdate from "./useUpdate";

type FieldValues<Schema extends yup.AnyObjectSchema> = {
  [key in keyof Schema["fields"]]: any;
};

type ValidationOptions<Schema extends yup.AnyObjectSchema> = Parameters<
  Schema["validate"]
>[1];

type RegisterOptions<
  Schema extends yup.AnyObjectSchema,
  Include extends keyof RegisterOptionalProps
> = Pick<JSX.IntrinsicElements["input"], "onChange" | "onFocus" | "onBlur"> & {
  ref?: React.MutableRefObject<any>;
  defaultValue?: any;
  include?: Include[];
  validate?(
    value: any,
    options: { fields: FieldValues<Schema>; options: ValidationOptions<Schema> }
  ): void;
  validationOptions?: ValidationOptions<Schema>;
};

type RegisterOptionalProps = {
  error?: Error;
  valid: boolean;
};

class Field {
  ref: React.MutableRefObject<any> = {
    current: null,
  };

  initialized = false;

  events = new EventEmitter<[["change"], ["focus"], ["blur"], ["touch"]]>();

  error: Error;

  touched: boolean;

  // Used to prevent submission even if field shows itself as valid. We want to
  // avoid turning the field red before the user finishes entering, but
  // submission should not be allowed
  preventSubmission = false;

  valid = true;

  value: any;

  init(ref?: React.MutableRefObject<any>, defaultValue?: any) {
    if (ref) this.ref = ref;
    this.value = defaultValue;

    this.initialized = true;

    return this;
  }

  touch() {
    this.touched = true;
    this.events.emit("touch");
  }
}

declare namespace useForm {
  type Options<
    Schema extends yup.AnyObjectSchema = yup.AnyObjectSchema,
    DefaultInclude extends keyof RegisterOptionalProps = keyof RegisterOptionalProps,
    DefaultRegisterOptions extends RegisterOptions<
      Schema,
      DefaultInclude
    > = RegisterOptions<Schema, DefaultInclude>
  > = {
    schema: Schema;
    error?: InteractiveError;
    fieldDefaults?: Partial<Record<keyof Schema["fields"], string>>;
    defaultRegisterOptions?: DefaultRegisterOptions;
    autoFocus?: true | keyof Schema["fields"];
    autoFocusDelay?: number;
  };
  type GetFields<T> = (() => T) & {
    validated: () => Promise<T>;
  };
}

interface UseFormOptions<
  Schema extends yup.AnyObjectSchema,
  DefaultInclude extends keyof RegisterOptionalProps,
  DefaultRegisterOptions extends RegisterOptions<Schema, DefaultInclude>
> {
  schema: Schema;
  error?: InteractiveError;
  fieldDefaults?: Partial<Record<keyof Schema["fields"], string>>;
  defaultRegisterOptions?: DefaultRegisterOptions;
  autoFocus?: true | keyof Schema["fields"];
  autoFocusDelay?: number;
}

export default useForm;
function useForm<
  Schema extends yup.AnyObjectSchema,
  DefaultInclude extends keyof RegisterOptionalProps,
  DefaultRegisterOptions extends RegisterOptions<Schema, DefaultInclude>
>(
  formOptions: useForm.Options<Schema, DefaultInclude, DefaultRegisterOptions>
) {
  const contextOptions = useContext(FormContext);
  const {
    schema,
    error = useError(),
    fieldDefaults,
    defaultRegisterOptions,
    autoFocus,
    autoFocusDelay,
  } = { ...contextOptions, ...formOptions } as typeof formOptions;

  const update = useUpdate();

  const fields = useRef<
    {
      [key in keyof Schema["fields"]]?: Field;
    }
  >(
    useMemo(() => {
      const _fields = {};
      // eslint-disable-next-line guard-for-in
      for (const name in schema.fields) {
        _fields[name] = new Field();
      }
      return _fields;
    }, [])
  ).current;

  const repeatFailedSubmitCount = useRef(0);

  const autoFocusedTo = useFocusOnMount({ delay: autoFocusDelay });

  const res = {
    register<Include extends keyof RegisterOptionalProps = DefaultInclude>(
      name: keyof Schema["fields"],
      fieldOptions: RegisterOptions<Schema, Include> = {}
    ) {
      const {
        include,
        onChange,
        onFocus,
        onBlur,
        ref,
        defaultValue = fieldDefaults?.[name],
        validate,
        validationOptions,
      } = { ...defaultRegisterOptions, ...fieldOptions };

      const initial = !fields[name]?.initialized;
      if (initial) {
        fields[name].init(ref, defaultValue);
        update();
      }

      const field = fields[name];

      function validateField({ noUpdate = false } = {}) {
        return (async () => {
          const fieldValues = Object.entries(fields).reduce(
            (values, [key, { value }]) => {
              values[key] = value;
              return values;
            },
            {}
          ) as FieldValues<Schema>;

          await validate?.(fieldValues[name], {
            fields: fieldValues,
            options: validationOptions,
          });

          await schema.validateAt(
            name as string,
            fieldValues,
            validationOptions
          );

          if (field.error === null) return { valid: true, shouldUpdate: false };

          if (!noUpdate) {
            field.valid = true;
            field.error = null;
          }
          return { valid: true, shouldUpdate: true };
        })().catch((err) => {
          if (field.error === err) return { valid: false, shouldUpdate: false };

          if (!noUpdate) {
            field.valid = false;
            field.error = err;
          }
          return { valid: false, shouldUpdate: true };
        });
      }

      function updateIf({ shouldUpdate = false }) {
        if (shouldUpdate) update();
      }

      if (initial) {
        field.events.on("touch", () => {
          repeatFailedSubmitCount.current = 0;
        });
        const refs = [
          ...schema.fields[name]._whitelist.refs,
          ...schema.fields[name]._blacklist.refs,
        ];
        for (const [refName] of refs) {
          fields[refName].events
            .on("blur", () => {
              if (field.touched) validateField().then(updateIf);
            })
            .and.on("change", () => {
              if (!field.valid) validateField().then(updateIf);
            });
        }
      }

      return {
        name,
        ref: (el) => {
          field.ref.current = el;
          if (
            // Bind autoFocus ref to first matching registration
            !autoFocusedTo.current &&
            (autoFocus === true || autoFocus === name)
          )
            autoFocusedTo.current = el as HTMLElement;
        },
        defaultValue,
        onFocus(e) {
          field.touch();

          onFocus?.(e);
          field.events.emit("focus");
        },
        onChange(e) {
          field.value = e.currentTarget.value;

          validateField(field.valid ? { noUpdate: true } : {}).then(
            ({ shouldUpdate, valid }) => {
              if (!shouldUpdate && field.preventSubmission === !valid) return;
              field.preventSubmission = !valid;
              update();
            }
          );

          if (!field.valid) validateField().then(updateIf);

          onChange?.(e);
          field.events.emit("change");
        },
        onBlur(e) {
          validateField().then(updateIf);

          onBlur?.(e);
          field.events.emit("blur");
        },
        ...(include &&
          (() => {
            const includedProps = {} as {
              [Key in typeof include[number]]: RegisterOptionalProps[Key];
            };

            for (const prop of include) {
              includedProps[prop] = field[prop];
            }

            return includedProps;
          })()),
      };
    },
    getFields: Object.assign(
      () =>
        Object.entries(fields).reduce((values, [key, { value }]) => {
          values[key] = value;
          return values;
        }, {}) as FieldValues<Schema>,
      {
        validated: () => {
          const fieldValues = res.getFields();

          return schema
            .validate(fieldValues, { abortEarly: false })
            .then(() => fieldValues);
        },
      }
    ),
    getValidatedFields() {
      const fieldValues = Object.entries(fields).reduce(
        (values, [key, { value }]) => {
          values[key] = value;
          return values;
        },
        {}
      ) as FieldValues<Schema>;

      return schema
        .validate(fieldValues, { abortEarly: false })
        .then(() => fieldValues);
    },
    handleSubmit<E extends React.SyntheticEvent>(
      handler: (e: E, data: FieldValues<Schema>) => void,
      { allowDefault = false, nativeThrow = false } = {}
    ) {
      return (e: E) => {
        if (!allowDefault) e.preventDefault();

        return res.getFields
          .validated()
          .then((values) => handler(e, values))
          .catch((err) => {
            if (!(err instanceof yup.ValidationError)) {
              if (nativeThrow) throw err;
              return error.throw(err);
            }

            // Show global error listing all issues if user tries to submit 3 or more times
            repeatFailedSubmitCount.current++;
            if (repeatFailedSubmitCount.current >= 3) {
              const errors = err.inner.map(({ message }) => message);
              const message =
                errors.length === 1 ? errors[0] : `\n- ${errors.join("\n- ")}`;

              if (nativeThrow) throw new Error(message);
              else error.throw(message);
            }

            for (const { path, message } of err.inner) {
              fields[path].error = new Error(message);
              fields[path].valid = false;
            }
            update();
          });
      };
    },
    submittable: Object.entries(fields).reduce(
      (submittable, [name, { valid, value, preventSubmission }]) =>
        submittable &&
        valid &&
        !preventSubmission &&
        (value || !schema.fields[name].exclusiveTests.required),
      true
    ),
    error,
  };

  return res;
}

export const FormContext = React.createContext(
  {} as Omit<useForm.Options, "schema">
);
